<template>
  <div class="ma-3">

    <Snackbar ref="snackbar"></Snackbar>

    <div class="Withdraws">
      <h3>Баланс карт - Noros payments</h3>
    </div>

    <div class="mt-5">
      <p><strong>Фильтр</strong> </p>
      <v-form>
        <v-row>

          <v-col
              cols="12"
              sm="6"
              md="2"
          >

            <v-text-field
                v-model="chatId"
                label="Chat ID Filter"
                outlined
                clearable
                type="number"
                min="0"
                oninput="validity.valid||(value='');"

            ></v-text-field>

          </v-col>



          <v-col v-if="!isOperator"
              cols="12"
              sm="6"
              md="2"
          >

            <CurrencySelect  v-on:changed="currencychanged"> </CurrencySelect>
          </v-col>

        </v-row>


      </v-form>
    </div>


    <div>Сумма балансов: {{sumRub.toLocaleString('ru-RU', {
      style: 'currency',
      currency: 'RUB'
    }) }}</div>

    <div>
      <v-data-table
          :items="cards"
          :headers="headers"
          class="elevation-1"
          sort-by="id"
          sort-desc
          dense

          :footer-props="{
                showFirstLastPage: true,
                showCurrentPage:true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',

                'items-per-page-text':'Показать на странице',
                'items-per-page-all-text':'Все',
                'items-per-page-options':
[
  50,
  100,
  200,
  -1
]
          }"
      >

        <template v-slot:item.id="{ item }"> {{ item.id }}</template>
        <template v-slot:item.number="{ item }"> {{ item.number }}</template>
        <template v-slot:item.owner="{ item }"> {{ item.owner }}</template>

        <template v-slot:item.bank="{ item }"> {{ item.bank ? item.bank.name : "--" }}</template>


        <template v-slot:item.status="{ item }"> {{ item.status ? "Активна" : "Выключена" }}
          <v-btn dense class="indigo" text @click="setActive(item, !item.status)">{{ item.status ? "Откл" : "Вкл" }}
          </v-btn>
        </template>

        <template v-slot:item.chatId="{ item }"> {{ item.chatId }}</template>
        <template v-slot:item.balance="{ item }"> {{  item.balance.toLocaleString('ru-RU')  }}</template>

        <template v-slot:item.curr="{ item }"> {{ get$TickerByCountry(item.bank.country) }} </template>



        <template v-slot:item.actions="{ item }">
          <v-btn v-if="!isOperator" class="orange" dense text @click="drop(item)">Сбр.</v-btn>

          <CardBalanceEditDialog caption="Кор." mode="edit" v-bind:item="item" v-on:ok="save"></CardBalanceEditDialog>

          <v-btn dense :to="'/history/'+item.id" color="primary">Ист.кор.</v-btn>

        </template>

      </v-data-table>
    </div>
  </div>

</template>

<script>

import CardBalanceEditDialog from '../components/dialogs/CardBalanceEditDialog.vue'
//import gql from "graphql-tag";
import {getHeaders} from "../store";

import {fetcher} from "../store";
import Snackbar from "../components/controls/Snackbar.vue";
import CurrencySelect from "../components/controls/CurrencySelect.vue";

const RestApiUrl = process.env.VUE_APP_REST_API_URL_ADMIN + "/card"

export default {
  name: 'Cards',

  components: {CurrencySelect, CardBalanceEditDialog, Snackbar},
/*
  apollo: {

      cards: {
        query: gql`

    query ($exp: cards_bool_exp! = {} )  {
    cards(where: {_and:[  {deletedAt: {_is_null: true}},  $exp ]} ) {
    comment

    id
    limit
    max
    min
    number
    owner
    status
    balance

    bank {
      country
    }

    chatId

    bank {
      id
      name
    }

    tg
  }
 }`,

        variables() {
          let country_exp =  {bank: {country: {_eq: `${ this.isOperator ? this.getCountry() : this.country }`}}}
          let id_exp = this.chatId ? {chatId: {_eq: this.chatId}} : {};
          let exp = {_and: [id_exp,country_exp]}
          return {
            exp: exp,
          }
        },

        result({data}) {
          this.cards = data.cards;
          this.computeSumm();
        },
      },
    },
*/

  data: () => ({

    country:"",
    chatId: 0,

    sumRub:0,

    cards: [],

    emptyItem: {
      number: "",
      bankId: "",
      owner: "",
      limit: "",
      min: "",
      max: "",
      comment: "",
      tg: {id: "", link: ""}
    },

    headers: [
      {text: 'id', value: 'id'},
      {text: 'Номер карты', value: 'number'},
      {text: 'Банк', value: 'bank'},
      {text: 'Держатель', value: 'owner'},
      {text: 'Активна', value: 'status'},
      {text: 'Баланс', value: 'balance'},
      {text: 'Валюта', value: 'curr'},

      {text: 'Ид чата', value: 'chatId'},

      {text: 'Действия', value: 'actions'},
    ],

  }),


  computed: {
    isOperator() {
      return this.$store.state.user.role === "operator"
    },
  },

  methods: {

    currencychanged(value){
      this.country = value===null ? "" :  value.country
    },

    getCountry() {
      return this.$store.state.user.country
    },

    get$ByCountry(country){

      switch (country){
        case "ru" : return "₽"
        case "en" : return "$"
        case "tr" : return "₺"

        default:
          return "def$"
      }
    },

    get$TickerByCountry(country){

      switch (country){
        case "ru" : return "RUB"
        case "en" : return "USD"
        case "tr" : return "TRY"

        default:
          return "default"
      }

    },


    computeSumm() {

      let sumRub = 0;


      this.cards.forEach(
          t => {
            //if (t.status === 'completed') {
              sumRub += t.balance

           // }
          }
      );

      this.sumRub = sumRub

    },




    dosnackbar(text) {
      this.$refs.snackbar.showit(text)
    },

    setActive(item, active) {
      item.status = active
      this.save0(item, "edit")
    },

    async save0(item, mode) {

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: mode === "new" ? 'POST' : 'PATCH',
        headers: headers,
        body: JSON.stringify(item)
      };

      let url = `${RestApiUrl}/${mode === "new" ? "" : item.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async save(item, type, type2, sum, comment, img) {

      /*

      PATCH /api/admin/card/:id/adjust
      type: req.body.type,    // "increment" или "decrement"
      amount: req.body.amount,
      comment: req.body.comment

       */


      let headers = await getHeaders()
      //headers['Content-Type'] = 'application/json'

      const formData  = new FormData();
      formData.append('type', type === 1 ? "decrement" : "increment")
      formData.append('from', type2 === 1 ?  "debit" : "credit" )
      formData.append('amount', sum )
      formData.append('comment', comment )
      formData.append('img', img )

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: formData
      };



    /*  const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify(
            {
              type: t,// type==="1" ? "decrement" : "increment",
              from: t2,
              amount: sum,
              comment: comment

            }
        )
      };*/

      let url = `${RestApiUrl}/${item.id}/adjust`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async drop(item) {

      //PUT /api/admin/card/:id

      if (!window.confirm(`Сбросить счетчик баланса карты? \n ID: ${item.id};
        Номер: ${item.number};
        Владелец: ${item.owner};
        Банк: ${item.bank ? item.bank.name : '-'};


            `)) {

        return
      }


      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PUT',
        headers: headers,
        // body: JSON.stringify(item)
      };

      let url = `${RestApiUrl}/${item.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async del(item) {


      if (!window.confirm(`УДАЛИТЬ карту !? \n ID: ${item.id};
        Номер: ${item.number};
        Владелец: ${item.owner};
        Банк: ${item.bank ? item.bank.name : '-'};


            `)) {

        return
      }


      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'DELETE',
        headers: headers,
        // body: JSON.stringify(item)
      };

      let url = `${RestApiUrl}/${item.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    fin(val, r = 2) {
      return Number.parseFloat(val).toFixed(r);
    },
  },

}
</script>
